<template>
  <v-form ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Funcionário que incluiu"
            v-model="funcionario"
            :readonly="true"
          ></v-text-field>
          <v-text-field class="mr-2" label="Data da inclusão" v-model="data" :readonly="true"></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headers" :items="produtos" class="elevation-0">
            <template v-slot:items="props">
              <td>{{ props.item }}</td>
            </template>
          </v-data-table>
        </div>
      </v-flex>
    </v-layout>
    <div class="form-btn">
      <v-btn @click="voltar" outline>Voltar</v-btn>
    </div>
  </v-form>
</template>
<script>
import ServicoSacola from "../../servicos/servicoSacola"
const servicoSacola = new ServicoSacola()
export default {
  data() {
    return {
      funcionario: "",
      data: "",
      produtos: [],
      id: null,
      headers: [{ text: "Código de Barras", align: "left", sortable: false, value: "produtos" }]
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoSacola.getSacolaById(this.id).then(res => {
        if (res.status === 200) {
          this.produtos = res.data.produtos
          this.funcionario = res.data.nomeUsuario
          this.data = res.data.data
        }
      })
    }
  },
  methods: {
    voltar() {
      this.$router.push({ name: "Sacola" })
    }
  }
}
</script>
