import axios from "axios"
export default class ServicoSacola {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Sacola`
  }
  buscaCodigoBarras(codigoBarras) {
    return axios({
      url: this.urlServico + `/GetInformacaoPorCodigoDeBarras?codigoBarra=${codigoBarras}`,
      method: "GET"
    })
  }
  liberarCodigoBarras(codigoBarras) {
    return axios({
      url: this.urlServico + `/LiberarCodigoDeBarras?codigoBarra=${codigoBarras}`,
      method: "GET"
    })
  }
  salvar(produtos) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/Salvar", produtos, config).catch(error => {
      alert("Erro ao salvar uma nova sacola " + error)
      return Promise.reject(error)
    })
  }
  getAll() {
    return axios({
      url: this.urlServico + `/GetAllSacolas`,
      method: "GET"
    })
  }
  getSacolaById(id) {
    return axios({
      url: this.urlServico + `/GetSacolaById?id=${id}`,
      method: "GET"
    })
  }
  getSacolaByIdProdutosSemEntrada(ids) {
    const params = new URLSearchParams()
    ids.forEach(id => params.append("ids", id))
    return axios({
      url: this.urlServico + `/GetSacolaByIdProdutosSemEntrada`,
      params,
      method: "GET"
    })
  }
}
